<template>
  <div class="page page-company-form">
    <div class="card card-custom gutter-b example example-compact">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">
            <div v-if="originalData.name">
              <small>Avans İade Nedeni</small><br />
              <span>{{ originalData.name }}</span>
            </div>
            <span v-else>Avans İade Nedeni</span>
            <div>
              <small>Avans İade Nedeni</small>
            </div>
          </h3>
        </div>
        <div class="d-block w-100 mb-2">
          <rs-return-to />
        </div>
      </div>
      <div class="card-body">
        <v-form ref="form" @submit.prevent="handleFormSubmit">
          <v-row dense>
            <v-col sm="10">
              <rs-text-field
                label="Avans İade Nedeni"
                :rules="[rules.required, rules.maxLength(formData.name, 100)]"
                v-model="formData.name"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
            <v-col sm="2">
              <v-switch
                v-model="formData.is_active"
                :label="formData.is_active ? $t('active') : $t('passive')"
                hide-details="auto"
                :readonly="disabled"
                :filled="disabled"
              />
            </v-col>
          </v-row>

          <rs-form-buttons
            :is-loading="isLoading"
            @cancel="handleCancelClick"
            @submit="handleFormSubmit"
            v-if="!disabled"
          />
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import hasForm from "@/view/mixins/hasForm";
import OptionsService from "@/core/services/options.service";

export default {
  mixins: [hasForm],
  props: {
    id: {
      type: Number,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    id() {
      this.load();
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.id) {
        this.load();
      }
    });
  },
  data() {
    return {
      formData: {
        name: null,
        is_active: true,
      },
    };
  },
  methods: {
    /**
     * Retrieves item data
     * @return {Promise} Resolves if data is retrieved
     */
    load() {
      this.isLoading = true;

      return this.$api
        .query(`payback-reasons/${this.id}`)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit(event) {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);
      if (this.id) {
        this.$api
          .put(`payback-reasons/${this.id}`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              OptionsService.getGenericList();
              return;
            }

            this.loadData(response);

            this.name = response.data.data.name;
            this.group_code = response.data.data.group_code;
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.$api
          .post(`payback-reasons`, formData)
          .then((response) => {
            this.$toast.success("Kaydedildi");
            if (event && event.closeOnSave) {
              this.$router.back();
              OptionsService.getGenericList();
              return;
            }

            this.$router.replace({
              name: "other-definitions.payback-reasons.edit",
              params: { id: response.data.data.id },
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleCancelClick() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.v-input--checkbox {
  label {
    margin-bottom: 0;
  }
}
</style>
